
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Font Awesome
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css);

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// jQuery UI
@import "../css/jquery-ui.min.css";

// Custom Styles
body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.container {
  //padding-top: 52px;
  //padding-bottom: 80px;
}

.bg-success {
  background-color: $color1;
}

.bg-warning {
  background-color: $color11;
}

.text-light {
  color: $color3;
}

.spacer {
  height: 20px;
}

a {
  color: $color4;
  font-weight: bold;

  &.plain {
    font-weight: normal;
  }
}

h1 {
  font-size: 36px;
  font-weight: bold;
}

h3 {
  font-weight: bold;

  &.light {
    font-weight: normal;
  }
}

h4.light, .light {
  font-weight: normal;
}

/*
.small, h1 .small {
  font-size: .85%;
  font-weight: normal;
}*/

.dark-bg {
  background: $color5;
}

.light-bg {
  background: $color6;
}

.navbar {
  border-radius: 0;
  border-width: 0;
  margin-bottom: 20px;
}

.nav > li > a {
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-default {
  border-width: 0 0 1px;
}

.navbar-admin, .bids tr.admin td {
  background-color: $color2;
}

.navbar-admin{
  color: $color6;
}

.bids tr.admin td {
  color: $color6;

  a {
    color: $color6;
  }
}

.navbar-admin {
  border-color: $color2;
  color: $color6;

  a {
    color: $color6;
  }
  li > a:hover {
    color: #000;
  }
  .navbar-toggle {
    border-color: #fff;
  }
  .navbar-toggle .icon-bar {
    background-color: #fff;
  }
}

.navbar-brand {
  padding: 5px;
}

.navbar-default .navbar-nav > {
  li > a:hover {
    background: $color7;
    color: $color6;
  }

  .active > a {
    background: $color7;
    color: $color6;

    &:hover, &:active {
      background: $color7;
      color: $color6;
    }
  }
}

.search {
  padding-top: 18px;
  padding-bottom: 18px;
}

.breadcrumb {
  margin: 20px 0 0 0;
  padding: 0;
  background: $color6;
}

button.yadcf-filter-reset-button {
    color: #636b6f;
    background-color: #fff;
    border-color: #ccc!important;
    border-width: 1px;
    border-radius: 3px;
    box-shadow: none;
    border-style: solid;
}

@media (min-width: 768px) {
  .items > div:nth-child(3n+1) {
    clear: left;
  }
}
@media (min-width: 992px) {
  #buttons-bar > .btn-group {
    float: right;
  }
}

.dropdown-form {
  form {
    min-width: 250px;
    padding: 10px;
  }

  .form-group {
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) {
  .navbar {
    .navbar-nav {
      display: inline-block;
      float: none;
      vertical-align: top;
    }

    .navbar-collapse {
      text-align: center;
    }
  }
}

.item {
  border: 1px solid $color10;
  padding: 0;
  margin-top: 27px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
}

.item-danger, .item.item-danger footer {
  border-color: #a94442;
}

.item, .primary-image {
  position: relative;
  overflow: hidden;
}

.bids>.row {
  border-top: 1px solid #ddd;
}

.bids>.row>div {
  padding-top: 5px;
  padding-bottom: 5px;
}

.status {
  position: absolute;
  top: -90px;
  left: -90px;
  display: block;
  width: 170px;
  height: 170px;
  line-height: 165px;
  text-align: center;
  transform: rotate(-45deg);
  color: $color6;
  padding-top: 54px;
  font-size: 21px;
  font-weight: bold;

  &.ended {
    background: #000;
  }
}

.item {
  img {
    height: 200px;
    margin: 30px auto;
  }

  footer {
    padding: 5px 20px;
    display: block;
    border-top: 1px solid $color10;
  }

  h3, h4 {
    margin: 0;
  }

  h3 {
    padding: 12px 20px;
    min-height: 80px;
  }

  h4 {
    line-height: 1.3em;
    font-weight: normal;
    margin-bottom: 9px;

    a {
      font-weight: normal;
    }
  }
}

@media (max-width: 767px) {
  .item {
    margin: 0 -20px;
    border-width: 1px 0 0;
    border-radius: 0;

    footer {
      border: 0;
    }
  }

  .items > div:nth-child(2n+1) {
    clear: left;
  }

  .item {
    img {
      margin: 0 auto;
      max-height: 100px;
    }

    h4 {
      padding: 0;
      margin: 0 0 5px;
    }
  }

  .status {
    font-size: 17px;
  }
}

.auction-details p {
  margin: 0;
}

.auction-details p.text-danger {
  font-weight: bold;
}

.current-bid {
  margin-top: 0;
}

.bid {
  margin-top: 20px;
  padding: 20px;

  input, .input-group {
    margin-bottom: 9px;
  }
}

.sort-by {
  .form-group {
    margin-right: 10px;
    .control-label {
      margin-right: 10px;
    }
  }
}

/* AUCTION ADMIN INFO */

.bids {
  > tbody > tr > td.avatar {
    width: 40px;
  }

  img {
    width: 24px;
  }
}

.modal-emails .list-group {
  border-radius: 0;
  box-shadow: 0 0 0;
}

/* ADMIN */

.navbar-btn {
  margin-left: 15px;
}

.bs-dropzone {
  position: relative;
  padding: 20px;
  color: $color8;
  text-align: center;
  border: 2px dashed #eee;
  border-radius: 4px;
}

.orders {
  width: 100%;

  .img-wrap {
    width: 40px;
  }

  .img-responsive {}

  > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid $color9;
  }

  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
    padding: 8px;
    line-height: 1.5;
    vertical-align: top;
    border-top: 1px solid $color9;
  }

  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
    //white-space: nowrap;
  }
}

.headerSortDown::after {
  border-bottom: 4px solid;
}

.headerSortUp::after, .headerSortDown::after {
  display: inline-block;
  width: 0px;
  height: 0px;
  content: "";
  margin-right: -10px;
  margin-bottom: 2px;
  margin-left: 5px;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.orders thead > tr > th {
  &.headerSortUp, &.headerSortDown {
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    color: $color4;
    border-bottom-color: $color4;
  }
}
.btn-danger {
    color: #a94442 !important;
    font-weight: bold !important;
}
//.autocomplete-list li {
//  display: block;
//  background-color: #f9ffff;
//  color: blue;
//  border: solid 1px #000;
//  width: 100%;
//  margin: 0;
//  padding: 5px;
//}
//.autocomplete-list ul {
//    top: -10px !important;
//    left: 45px !important;
//    width: 90% !important;
//}

// datepicker
.ui-datepicker {
  max-width: 320px;
  min-width: 240px;
  background-color: #fff;
  padding: 10px;
  border: solid 1px #000;
}
.ui-datepicker-calendar{
  width: 100%;
}
.ui-datepicker-calendar a {
  text-align: center;
  display: block;
  background-color: #ccc;
  padding: 0px 10px 0px 10px;
  margin: 2px 2px 2px 2px;
}
.ui-datepicker-calendar thead th {
  text-align: center
}
.ui-datepicker-next {
  float: right;
}
.ui-datepicker-title {
  display: inline-block;
  position: relative;
  text-align: center;
  padding-left: 25%;
  font-weight: bold;
}
.ui-datepicker-header {
  background-color: #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

#dashboardTable .img-responsive {
  min-width: 22px;
  max-width: 25px;
  height: auto;
}
#dashboardTable th.header:hover {
  cursor: pointer;
}
.image-edition input[type="radio"] {
  visibility: hidden;
}
.image-edition input[type="radio"]:checked + img {
    border: 2px solid #2ab27b;
}
.image-edition .image-button-group {
  text-align: center;
}
.image-edition .image-button-group button {
  margin-left: 15px;
}
.image-edition .image-button-group img {
  object-fit: contain;
  width: 125px;
  height: 125px;
}
.btn-admin.btn-danger {
  color: #fff !important;
  font-weight: bold !important;
}
.yadcf-filter-wrapper input{
  width: 50%;
  margin: 0;
  height: 37px;
  padding: 6px 6px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.yadcf-filter-wrapper {
  display: inline-flex;
}
footer {
  text-align: center;
}

// Add group member page
.add-group-member {
  .search-label {
   padding-top: 12px;
  }
  .search-input {
    padding-top: 5px;
  }
}

// Add the loading spinner
.loader {
  background-image: url(../assets/loading-small.gif);
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  //background-size: 100px;
  opacity: 0.5;
  z-index: 101;
  background-color: #ddd;
}
